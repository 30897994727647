<template>
    <div class="notifications text-left">
        <div class="inner-notifications">
            <div class="container-fluid my-3">
                <h3>All Notifications</h3>
            </div>
            <hr style="background:#2d2d2d;" class="mt-4">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-6">
                        <div v-for="(data, index) in data_notif" :key="index" class="container-fluid">
                            <div class="row mt-4">
                                <div class="col-1 d-flex align-items-start justify-content-center">
                                    <img v-if="!data.seen" src="@/assets/images/icons/ic_notif_ellipse.svg" alt="dot" class="img-fluid mt-1">
                                </div>
                                <div class="col-11">
                                    <p class="font-weight-normal mb-1 text-white mr-1">{{ data.title }}</p>
                                    <i>{{ data.createdAt }}</i>
                                </div>
                            </div>
                            <hr class="mt-2" style="background: #2D2D2D">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data_notif: [
                {
                    title : 'Your password has been successfully changed.',
                    seen : false,
                    createdAt : '3 mins ago'
                },
                {
                    title : 'Your password has been successfully changed.',
                    seen : false,
                    createdAt : '1 hours ago'
                },
                {
                    title : 'Your password has been successfully changed.',
                    seen : true,
                    createdAt : '1 days ago'
                },
            ]
        }
    },
}
</script>

<style scoped>
.notifications {
    color: white;
}

.inner-notifications h3 {
    font-size: 24px;
}

.inner-notifications i {
    font-size: 12px;
    color: #8C8C8C;
}
</style>